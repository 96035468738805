.table {
    & .table_header{
        font-weight: 600;
        font-size: 16px;
        background-color: #F5F5F5;
        padding: 15px;
        border-radius: 5px;
    }    
    & .table_body{
        border: 1px solid rgba(0,0,0,.05);
    }
}

// .ant-modal-close{
//     display: none;
// }

.ant-modal-content{
    padding: 0px  !important;
}

.radio-container input[type=radio] {
    // -webkit-appearance: none;
    border: 2px solid #012a4d;
    border-radius: 50%;
    height: 16px;
    outline: none;
    width: 20px;
    accent-color: #012a4d;
}

input[type=checkbox], input[type=radio] {
    box-sizing: border-box;
 
}
  


  /* Style the checked radio button */
  .radio-container input[type="radio"]:checked {
    background-color: #012a4d; /* Checked color */
    
  }



  
  