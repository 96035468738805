* {
  font-family: "Nunito Sans", sans-serif;
}
.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  display: none;
}
.ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  display: none;
}
.ant-table-wrapper .ant-table-tbody .ant-table-row > .ant-table-cell-row-hover {
  background: none;
}
.ant-dropdown .ant-dropdown-menu {
  padding: 10px;
}

.ant-input {
  padding: 10px;
}
.ant-input-affix-wrapper {
  padding: 10px;
}
body {
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 16px;
  top: 0px !important;
}

.ant-progress {
  border-radius: 10px; /* Adjust the value as needed */
}

:root {
  --width: 450px;
  --backgroundColor: #f5f5f5;
}
.pointer {
  cursor: pointer;
}
.mt_10 {
  margin-top: 10px;
}
.mt_20 {
  margin-top: 20px;
}
.ml_10 {
  margin-left: 10px;
}
.pt_25 {
  padding-top: 25px;
}
.W_800 {
  font-weight: 800;
}
.W_600 {
  font-weight: 600;
}
.W_700 {
  font-weight: 700;
}
.W_500 {
  font-weight: 500;
}
.fs_24 {
  font-size: 24px;
}
.fs_15 {
  font-size: 15px;
}
.fs_16 {
  font-size: 16px;
}
.p_20 {
  padding: 20px;
}
.p_30 {
  padding: 30px;
}
.width100 {
  width: 100%;
}
.width15 {
  width: 15%;
}
.width10 {
  width: 10%;
}
.width8 {
  width: 8%;
}
.width6 {
  width: 6%;
}
.width12 {
  width: 12%;
}
.width5 {
  width: 5%;
}
.navbar {
  background-color: #f2f3f7;
  .header {
    padding: 10px 30px;

    /* display: flex; */
    /* justify-content: space-between; */
    /* align-items: center; */
    display: grid;
    grid-template-columns: 5fr 1fr;
    .header_head {
      align-self: center;
    }
    .header_side {
      display: flex;
      justify-content: end;
      align-items: center;
      /* display: grid;
      grid-template-columns: 4fr 1fr;
      justify-items: end;
      align-items: center; */
      .select_project {
        .ant-select-single {
          height: 40px;
          width: 180px;
          .ant-select-selector {
            padding: 0px 10px;
            background: #ffffff;
            .ant-select-selection-item {
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
        .ant-select-arrow {
          padding-right: 10px;
        }
      }
    }
  }
}
.top_card {
  padding: 30px;
  background-color: #fafafa;
  border-radius: 10px;
  .cards {
    display: flex;
    .title {
      color: #757575;
    }
    .cardValue {
      margin-top: 10px;
      color: #333333;
    }
    .card {
      padding-right: 3%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 50px;
      .indiv_card {
      }
    }
    .card:not(:first-child) {
      padding: 0% 3%;
      border-left: 1px solid #1c1c1c1a;
    }
    .custom-progress-bar {
      border-radius: 10px !important; /* Set your desired border radius */
    }
  }
}
.py_card {
  border: 1px solid #e1e1e166;
  padding: 30px;
  margin: 20px 0px 35px 0px;

  .paymentCard {
    display: flex;
    .card {
      padding-right: 5%;
      /* margin-right: 5%; */
      width: 30%;
      display: flex;
      justify-content: flex-start;

      gap: 7%;
      align-items: center;
      .img {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 11px;
        border-radius: 5px;
        background-color: #0b808012;
      }

      .value {
        font-size: 20px;
        width: 150px;
      }
      .title {
        font-weight: 600;
        color: #757575;
      }
    }
    .card:not(:first-child) {
      padding: 0% 5%;
      border-left: 1px solid #1c1c1c1a;
    }
  }
}
.documents {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 20px;

  .card {
    background-color: #edf6f6;
    border: 1px solid #dbeaf9;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    min-width: 300px;
    .left {
      display: flex;
      align-items: center;
    }
    .right {
      display: flex;
      align-items: center;
    }
  }
}

.custom-progress-bar {
  .ant-progress-outer {
    .ant-progress-inner {
      border-radius: 8px !important;
      .ant-progress-bg {
        border-radius: 8px !important;
        background-color: #ffcfad;
      }
    }
  }
}

.customTabs {
  .ant-tabs-nav {
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          .ant-tabs-tab-btn {
            font-weight: 600;
            font-size: 16px;
            color: #757575;
          }
        }
        .ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            font-weight: 600;
            font-size: 16px;
            color: #0b8080;
          }
        }

        .ant-tabs-ink-bar {
          background-color: #0b8080; /* Change 'red' to your desired color */
        }
      }
    }
  }
}

.btn_primary {
  background-color: #0b8080;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  padding: 8px 12px;
  box-shadow: none;
  /* border: #0B8080; */
  border: none;
  border-radius: 5px;
  white-space: nowrap;
}

.btn_primary_full_length {
  background-color: #0b8080;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  padding: 14px;
  box-shadow: none;
  /* border: #0B8080; */
  border: none;
  border-radius: 5px;
  width: 100%;
}

.btn_sec {
  background-color: #ffffff;
  color: #0b8080;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  padding: 8px 12px;
  box-shadow: none;
  border: 1px solid #0b8080;
  border-radius: 5px;
}

.customTable {
  .ant-table-wrapper .ant-table-thead > tr > th {
    padding: 12px 10px;
    font-weight: 600;
    font-size: 14px;
    color: #757575;
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 10px;
    font-weight: 500;
    font-size: 16px;
    .end {
      display: flex;
      justify-content: center;
      /* align-items: center; */
      gap: 30px;
      /* padding-left: 50px; */
      padding-right: 20px;
    }
  }
}
.photoCards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(438px, 1fr));
  gap: 30px;
  .card {
    border: 1px solid #dbeaf9;
    border-radius: 6px;
    box-shadow: 0px 3px 3px 0px #00000008;
    .card_header {
      width: 100%;
      .photo {
        object-fit: cover;
        width: 100%;
        max-height: 80px;
      }
    }
    .card_footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      background-color: #f7fbfb;
      font-weight: 600;
      font-size: 16px;
      color: #333333;
    }
  }
}

.login_parent {
  height: 100vh;
  display: grid;
  .login_child {
    min-width: 420px;
    margin: auto;
    place-items: center;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
    padding: 30px;
    .login_logo {
      display: flex;
      justify-content: center;
    }
    .login_template {
      color: #000;
      font-weight: 700;
      font-size: 22px;
    }
    .login_fields {
      margin-bottom: 50px;
      .label {
        color: #22212f !important;
        margin-bottom: 8px;
      }
      .field {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
      }
    }
  }
}

.chatContainer-nodata{
  width: 100%;
  background-color: #fafafa;
  height: 450px;
}
.chatContainer {
  /* height: 500px; */
  width: 100%;
  background-color: #fafafa;
  /* padding: 20px; */
  .chat_body {
    padding: 30px;
    overflow-y: scroll;
    height: 450px;
  }
  overflow: auto;
  .left_container {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;

    .left_card {
      padding: 15px;
      background-color: #0b8080;
      border-radius: 0px 10px 10px 10px;
      color: #ffffff;
      font-weight: 600;
      max-width: 400px;
      word-break: break-all;
    }
  }

  .right_container {
    display: flex;
    justify-content: end;
    margin-bottom: 20px;
    .right_card {
      padding: 15px;
      background-color: #ffffff;
      border-radius: 10px 0px 10px 10px;
      border: 1px solid #e4e9ee;
      color: #757575;
      font-weight: 600;
      max-width: 400px;
      text-wrap: wrap;
      word-break: break-all;
    }
  }
}

.chatContainer_footer {
  border-top: 1px solid #e5e9eb;
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fafafa;
  .customTextarea {
    width: 90%;
    padding: 10px;
    border: none;
    font-size: 16px;
    background-color: #fafafa;
    resize: none;
  }
  .customTextarea:focus {
    outline: none;
  }
  .textarea_top {
  }
}

.table {
  .table_header {
    font-weight: 600;
    font-size: 16px;
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 5px;
  }
  .table_body {
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    gap: 20px;
    .profilelabel {
      color: #757575;
      font-size: 14px;
      margin-bottom: 0.5rem;
      font-family: "Poppins", sans-serif;
      font-weight: 400;
    }
    .profileInput {
      border: none;
      border-bottom: 1px solid #e1e1e1;
      border-radius: 0px;
      outline: none;
      padding: 10px 0px;
    }
    .profileInput:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.breadCrumbsContainer {
  display: flex;
  .breadCrumbs {
    display: flex;
    .name {
      color: #babfc3;
      font-weight: 600;
    }
    .active {
      color: #000;
    }
    .nonactive {
      cursor: pointer;
    }
    .separator {
      padding: 0px 10px;
      color: #babfc3;
    }
  }
}

.profile_container {
  background-color: #fafafa;
  .profile_header {
    background: #1c1c1c;
  }
  .table {
    .table_body {
      .input {
        input {
          /* background-color: #000; */
        }
      }
    }
  }
  .ant-input:placeholder-shown {
    background-color: #fafafa;
  }
}

.customDropDown {
  .ant-dropdown .ant-dropdown-menu {
    padding: 10px;
  }
}

.payMethodHeader {
  background: #eef6f7;
}
.fw-500 {
  font-weight: 500 !important;
}

.fw-400 {
  font-weight: 400;
}

.f12 {
  font-size: 12px;
}

.f13 {
  font-size: 13px;
}

.f14 {
  font-size: 14px;
}

.f15 {
  font-size: 15px;
}

.f18 {
  font-size: 18px;
}

.f20 {
  font-size: 20px;
}

.ff-pop {
  font-family: "Poppins" !important;
}
.cl212121 {
  color: #212121 !important;
}
.point {
  cursor: pointer !important;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-15 {
  margin-bottom: 15px;
}

.custom_pri_btn {
  padding: 10px 23px;
  font-size: 13px;
  background-color: #1e8080;
  border: none;
  cursor: pointer;
  font-weight: 500;
  border-radius: 5px;
  color: white;
  transition: 0.5s;
}

.custom_pri_btn:hover {
  background-color: #1e8080;
}

.custom_sec_btn {
  padding: 10px 23px;
  font-size: 13px;
  border: 1px solid;
  cursor: pointer;
  font-weight: 500;
  border-radius: 5px;
  background-color: transparent;
  color: #1e8080;
  transition: 0.5s;
}
.conCanMod {
  font-size: 20px;
  text-align: center;
  color: #212121;
  font-weight: 500;
  margin-bottom: 16px;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.button-secondary {
  background-color: unset !important;
  color: #002a4d;
}

.button-secondary:hover {
  background-color: unset !important;
  color: #002a4d;
}

.hide-cell {
  display: none;
}
.customTable .ant-table-wrapper .ant-table-tbody > tr > td {
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 500;
  font-size: 16px;
  height: 56px;
}
.notification-container {
  font-size: 14px;
  box-sizing: border-box;
  position: fixed;
  z-index: 2000;
  animation: animate_div 0.6s 1;
  transition: 3s all;
}
.toast-flex {
  display: flex;
  margin-left: 1rem;
}

.toast-img {
  width: 1.5rem;
  padding-top: 5px;
}
.toast {
  display: flex;
  padding: 0px 0px !important;
  min-width: 345px !important;
  max-width: unset !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 5px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  animation: animate_div 0.6s 1;
  transition: 3s all;
  font-size: 11px !important;
}
.notification-title,
.notification-message {
  color: #434343;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
}
.closeToast-img {
  margin-left: 2rem;
  cursor: pointer;
  margin-top: 8px;
}

.top-center {
  top: 12px;
  right: 50%;
  transform: translateX(26%);
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
}
.notification toast {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-center-css {
  text-align: center;
}

.failure-status {
  color: #757575;
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 12px;
}
.text-center-css {
  color: #757575;
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
}

.pay-paid {
  display: none;
}

.transaction-tag {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 14px;
  color: #757575;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.currency-value {
  color: #7cfc00;
  font-size: 24px;
  font-weight: 800;
  padding-bottom: 12px;
}
.drawer {
  border-radius: 10px 0px 0px 0px;
}

.ant-drawer-body {
  padding: 0px !important;
  border-radius: 10px 0px 0px 0px;
}

/* style={{width:"100%",background:"#1E8080",justifyContent:"end",alignItems:'center',display:"flex"}} */

.drawer-header {
  width: 100%;
  background-color: #1e8080;
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.drawer-title {
  padding-left: 24px;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
}
.close-icon {
  padding-right: 24px;
  cursor: pointer;
}
.timeLineRound {
  background-color: #d3d3d3;
  border-radius: 25px;
  width: 9px;
  height: 9px;
}

.timeLineVerLine {
  border-left: 1px solid #dad9d9;
  height: 100%;
  margin-left: 4px;
}

.timeline-msg {
  font-size: 14px;
  font-weight: 600;
  color: #525f7f;
  padding-bottom: 10px;
}
.timeline-time {
  color: #787878;
  font-size: 14px;
  font-weight: 500;
}
.timeline-reason {
  margin-left: 29px;
  margin-right: 29px;
  padding: 10px;
  background-color: #f8f8f8;
  width: auto;
  font-size: 14px;
  font-weight: 700;
  color: #525f7f;
}

.reason-value {
  font-size: 14px;
  font-weight: 500;
  color: #525f7f;
  word-break: break-all;
}
.drop-style {
  display: flex;
  justify-content: center;
}
.dot-align {
  display: flex;
  align-items: center;
  height: 100%;
  padding-top: 2px;
  cursor: pointer;
}
.time-padding {
  padding-right: 20px;
}
.spin-loading1 {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
}

.doc-title {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #333333 !important;
}
.doc-subtitle {
  font-size: 10px !important;
  font-weight: 400 !important;
  color: #757575 !important;
  padding-top: 8px;
}
.reduceopacity{
  opacity: 50%;
}